import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import BlastLogo from '../../assets/svg/dapp/blast_logo.webp'
import { ReactComponent as GalaxyLogo } from '../../assets/svg/dapp/galaxy_logo.svg'
import { ReactComponent as RangoLogo } from '../../assets/svg/dapp/rango_logo.svg'
import { ReactComponent as RouterLogo } from '../../assets/svg/dapp/router_logo.svg'

export default function Bridge() {
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  return (
    <div className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8 max-w-7xl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2">Everything you need, all in one place.</h1>
          <h2 className="text-2xl mb-6">Shaping the decentralized future</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 max-w-7xl mx-auto">
          {/* blast bridge */}
          <div className="cursor-pointer group">
            <div
              className={`${
                isDarkMode ? 'bg-[#15171c] border-white border-opacity-10' : 'bg-white border-[#22222212]'
              } rounded-xl overflow-hidden transition-colors duration-200 relative border`}
            >
              <div className="p-4">
                <div className="flex mb-2">
                  <div
                    className={`flex items-center w-full aspect-[2/1] flex-shrink-0 ${
                      isDarkMode ? 'bg-black' : 'bg-[#F9F9F9]'
                    } rounded-xl overflow-hidden`}
                  >
                    <img src={BlastLogo} className="w-full px-8 object-cover group-hover:scale-110 duration-200" />
                  </div>
                </div>
                <div className="p-1 mb-2">
                  <h3 className="text-lg font-semibold mb-1">Blast Bridge</h3>
                  <p className="text-sm text-gray-600 line-clamp-4 h-20">Deposit tokens to the Blast network.</p>
                </div>
                <Link
                  style={{ color: theme.white }}
                  to="https://blast.io/bridge"
                  target="blank"
                  className="flex w-full no-underline py-2"
                >
                  <button
                    className="flex w-full items-center bg-[#4673f1] hover:bg-[#2d60f9] justify-center whitespace-nowrap font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none h-[44px] rounded-[8px] px-6 py-[11px] text-lg leading-[20px]"
                    type="button"
                  >
                    Launch App
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* rango */}
          <div className="cursor-pointer group">
            <div
              className={`${
                isDarkMode ? 'bg-[#15171c] border-white border-opacity-10' : 'bg-white border-[#22222212]'
              } rounded-xl overflow-hidden transition-colors duration-200 relative border`}
            >
              <div className="p-4">
                <div className="flex mb-2">
                  <div
                    className={`w-full aspect-[2/1] flex-shrink-0 ${
                      isDarkMode ? 'bg-white' : 'bg-[#F9F9F9]'
                    } rounded-xl overflow-hidden`}
                  >
                    <RangoLogo className="w-full aspect-[2/1] object-cover p-8 group-hover:scale-110 duration-200" />
                  </div>
                </div>
                <div className="p-1 mb-2">
                  <h3 className="text-lg font-semibold mb-1">Rango Exchange</h3>
                  <p className="text-sm text-gray-600 line-clamp-4 h-20">
                    Rango offers smart routing over DEXs, DEX aggregators, bridges and cross-chain protocols to provides
                    cross-chain swaps, interoperability and message passing.
                  </p>
                </div>
                <Link style={{ color: theme.white }} to="/bridge/rango" className="flex w-full no-underline py-2">
                  <button
                    className="flex w-full items-center bg-[#4673f1] hover:bg-[#2d60f9] justify-center whitespace-nowrap font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none h-[44px] rounded-[8px] px-6 py-[11px] text-lg leading-[20px]"
                    type="button"
                  >
                    Launch App
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* Galaxy Exchange */}
          <div className="cursor-pointer group">
            <div
              className={`${
                isDarkMode ? 'bg-[#15171c] border-white border-opacity-10' : 'bg-white border-[#22222212]'
              } rounded-xl overflow-hidden transition-colors duration-200 relative border`}
            >
              <div className="p-4">
                <div className="flex mb-2">
                  <div
                    className={`flex items-center w-full aspect-[2/1] flex-shrink-0 ${
                      isDarkMode ? 'bg-black' : 'bg-[#c1bbf6]'
                    } rounded-xl overflow-hidden`}
                  >
                    <GalaxyLogo className="w-full object-cover overflow-hidden p-10 group-hover:scale-110 duration-200" />
                  </div>
                </div>
                <div className="p-1 mb-2">
                  <h3 className="text-lg font-semibold mb-1">Galaxy Bridge</h3>
                  <p className="text-sm text-gray-600 line-clamp-4 h-20">
                    Galaxy Exchange by Swing provides instant access to cross-chain liquidity from over 100 blockchains,
                    bridges, and liquidity aggregators.
                  </p>
                </div>
                <Link
                  style={{ color: theme.white }}
                  target="blank"
                  to="https://galaxy.exchange/swap?affiliate=ring-exchange"
                  className="flex w-full no-underline py-2"
                >
                  <button
                    className="flex w-full items-center bg-[#4673f1] hover:bg-[#2d60f9] justify-center whitespace-nowrap font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none h-[44px] rounded-[8px] px-6 py-[11px] text-lg leading-[20px]"
                    type="button"
                  >
                    Launch App
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* Router Intents */}
          <div className="cursor-pointer group">
            <div
              className={`${
                isDarkMode ? 'bg-[#15171c] border-white border-opacity-10' : 'bg-white border-[#22222212]'
              } rounded-xl overflow-hidden transition-colors duration-200 relative border`}
            >
              <div className="p-4">
                <div className="flex mb-2">
                  <div
                    className={`flex items-center w-full aspect-[2/1] flex-shrink-0 ${
                      isDarkMode ? 'bg-white' : 'bg-[#F9F9F9]'
                    } rounded-xl overflow-hidden`}
                  >
                    <RouterLogo className="w-full object-cover overflow-hidden group-hover:scale-110 duration-200" />
                  </div>
                </div>
                <div className="p-1 mb-2">
                  <h3 className="text-lg font-semibold mb-1">Router Intents</h3>
                  <p className="text-sm text-gray-600 line-clamp-4 h-20">
                    Ring Intent Adapter designed to streamline liquidity provisioning directly to Blast Chain from any
                    chain and using any token.
                  </p>
                </div>
                <Link
                  style={{ color: theme.white }}
                  target="blank"
                  to="https://poc-intents-ui.vercel.app/apps/ring"
                  className="flex w-full no-underline py-2"
                >
                  <button
                    className="flex w-full items-center bg-[#4673f1] hover:bg-[#2d60f9] justify-center whitespace-nowrap font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none h-[44px] rounded-[8px] px-6 py-[11px] text-lg leading-[20px]"
                    type="button"
                  >
                    Launch App
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
