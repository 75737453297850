import { useWeb3React } from '@web3-react/core'
import { isSupportedChain } from 'constants/chains'
import { ArrowUpRight } from 'react-feather'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { ReactComponent as RouterLogo } from '../../assets/svg/dapp/router_logo.svg'

export function RouterIntentsAlert() {
  const { chainId } = useWeb3React()
  const theme = useTheme()

  if (!chainId || !isSupportedChain(chainId)) return null

  return (
    <Link
      style={{ color: theme.white }}
      target="blank"
      to="https://poc-intents-ui.vercel.app/apps/ring"
      className="flex w-full no-underliner"
    >
      <div className="shine-border mt-2 w-full">
        <div className="rounded-xl relative border w-full cursor-pointe bg-black">
          <div className="flex w-full items-center p-1">
            <div className="w-full">
              <div className="flex flex-col h-full justify-center p-2">
                <h3 className="text-lg font-semibold mb-1">
                  <RouterLogo className="w-20 h-auto object-cover" />
                </h3>
                <p className="text-sm">Streamline liquidity provisioning to Blast Chain from any chain.</p>
              </div>
            </div>
            <ArrowUpRight width="24px" height="24px" color="#fff" />
          </div>
        </div>
      </div>
    </Link>
  )
}
